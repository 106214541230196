import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, map, } from "rxjs/operators";
import { throwError as observableThrowError, Observable } from 'rxjs';
import { DataService } from './data.service';
import { LoaderService } from '../core/loader/loader.service';
import { CustomMessageService } from './custom-message.service';
import { Environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RestService {
  public options = {};
  public disableLoader = false;
  public blobOutput = false;
  constructor(
    private http: HttpClient,
    private loaderService: LoaderService,
    private dataService: DataService,
    private messagingService: CustomMessageService
  ) { }

  /**
   * GET method implementation in common rest service
   * @param url - get url
   * @param param - url params
   * @param [disableLoader] - enable/disable loader
   * @param [blobOutput] - enable/disable output stringify
   * @param [html] - add headers for html
   * @returns `Observable stream` of the api response
   */
  get(
    url: string,
    param: any,
    disableLoader?: boolean,
    blobOutput?: boolean,
    html?: boolean,
  ): Observable<{}> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    if (localStorage.getItem('access_token') != null) {
      headers = headers.append('access_token', localStorage.getItem('access_token') || '{}');

    }
    this.disableLoader = disableLoader ? disableLoader : false;
    this.blobOutput = blobOutput ? blobOutput : false;

    let responseType;
    if (!this.disableLoader) {
      this.loaderService.show();
    }
    let queryParams = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        queryParams = queryParams.append(key, param[key]);
      }
    }
    if (this.blobOutput) {
      headers = headers.set('Accept', 'application/octet-stream');
      responseType = 'blob';
    } else {
      headers = headers.delete('Accept');
      responseType = 'json';
    }
    this.options = {
      headers,
      params: queryParams,
      responseType,
      withCredentials: true,
    };
    return this.http.get(url, this.options).pipe(
      map((data: any) => this.handleResponse(data, this)),
      catchError((error: any) => {
        this.handleError(error, this);
        return observableThrowError(error);
      })
    );
  }

  /**
   * POST method implementation in common rest service
   * @param url - get url
   * @param param - url params
   * @param [disableLoader] - enable/disable loader
   * @param [upload] - add headers for form-data
   * @param [blobOutput] - enable/disable output stringify
   * @returns `Observable stream` of the api response
   */
  post(
    url: string,
    param: any,
    disableLoader?: boolean,
    upload?: boolean,
    blobOutput?: boolean
  ): Observable<{}> {
    let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    if (localStorage.getItem('access_token') != null) {
      headers = headers.append('access_token', localStorage.getItem('access_token') || '{}');

    }

    this.disableLoader = disableLoader ? disableLoader : false;
    let responseType = 'json';
    this.blobOutput = blobOutput ? blobOutput : false;
    let body = param;
    if (upload) {
      // Other header params are not accepted in upload (server)
      // headers = new HttpHeaders({
      //   enctype: 'multipart/form-data',
      // });
      headers = headers.append('enctype', 'multipart/form-data');
    } else {
      headers = headers.append('Content-Type', 'application/json');
      body = JSON.stringify(param);
    }

    if (!disableLoader) {
      this.loaderService.show();
    }
    if (this.blobOutput) {
      headers = headers.set('Accept', 'application/octet-stream');
      responseType = 'blob';
    }
    this.options = {
      headers,
      responseType,
      withCredentials: true,
    };

    return this.http.post(url, body, this.options).pipe(
      map((data: any) => this.handleResponse(data, this)),
      catchError((error: any) => {
        this.handleError(error, this);
        return observableThrowError(error);
      })
    );
  }
  /**
   * PUT method implementation in common rest service
   * @param url - get url
   * @param param - url params
   * @param [disableLoader] - enable/disable loader
   * @param [upload] - add headers for form-data
   * @returns `Observable stream` of the api response
   */
  public put(url: string, param: any, disableLoader?: boolean, upload?: boolean): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    if (localStorage.getItem('access_token') != null) {
      headers = headers.append('access_token', localStorage.getItem('access_token') || '{}');

    }
    this.disableLoader = disableLoader ? disableLoader : false;
    let body = param;
    if (upload) {
      // Other header params are not accepted in upload (server)
      headers = headers.append('enctype', 'multipart/form-data');
    } else {
      headers = headers.append('Content-Type', 'application/json');
      body = JSON.stringify(param);
    }

    if (!disableLoader) {
      this.loaderService.show();
    }

    this.options = {
      headers,
      withCredentials: true,
    };

    return this.http.put(url, body, this.options).pipe(
      map((data: any) => this.handleResponse(data, this)),
      catchError((error: any) => {
        this.handleError(error, this);
        return observableThrowError(error);
      })
    );
  }

  /**
   * DELETE method implementation in common rest service
   * @param url - get url
   * @param param - url params
   * @param [disableLoader] - enable/disable loader
   * @returns  `Observable stream` of the api response
   */
  public delete(url: string, param: any, disableLoader?: boolean, body?: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    if (localStorage.getItem('access_token') != null) {
      headers = headers.append('access_token', localStorage.getItem('access_token') || '{}');

    }

    this.disableLoader = disableLoader ? disableLoader : false;

    if (!this.disableLoader) {
      this.loaderService.show();
    }

    let queryParams = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        queryParams = queryParams.append(key, param[key]);
      }
    }
    this.options = {
      headers,
      body,
      params: queryParams,
      withCredentials: true,
    };

    return this.http.delete(url, this.options).pipe(
      map((data: any) => this.handleResponse(data, this)),
      catchError((error: any) => {
        this.handleError(error, this);
        return observableThrowError(error);
      })
    );
  }

  public oauthLogin(
    url: string,
    param: any,
    disableLoader?: boolean,
    isRefresh?: boolean
  ): Observable<any> {
    if (!disableLoader) {
      this.loaderService.show();
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ` + Environment.btoa,
    });
    this.disableLoader = disableLoader ? disableLoader : false;
    let bodyz = '';
    if (!isRefresh) {
      const body = new HttpParams()
        .set('username', param.username)
        .set('password', param.password)
        .set('grant_type', 'password');
      bodyz = body.toString();
    } else {
      const body = new HttpParams().set('grant_type', 'refresh_token');
      bodyz = body.toString();
    }
    this.options = {
      headers,
      withCredentials: true,
    };
    return this.http.post(url, bodyz, this.options).pipe(
      map((data: any) => this.handleOauthResponse(data, this)),
      catchError(error => this.handleOauthError(error, this))
    );
  }

  public oauthLogout(
    url: string,
    disableLoader?: boolean,
  ): Observable<any> {
    if (!disableLoader) {
      this.loaderService.show();
    }
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    if (localStorage.getItem('access_token') != null) {
      headers = headers.append('access_token', localStorage.getItem('access_token') || '{}');
    }
    this.disableLoader = disableLoader ? disableLoader : false;
    this.options = {
      headers,
    };
    return this.http.get(url, this.options).pipe(
      map((data: any) => this.handleOauthResponse(data, this)),
      catchError(error => this.handleOauthError(error, this))
    );
  }

  private handleOauthResponse(res: Response, that: any): any {
    that.loaderService.hide();
    const body: any = res;
    return body || {};
  }
  private handleOauthError = (error: any, that: any) => {
    if (!that.disableLoader) {
      that.loaderService.hide();
    }

    if (error) {
      this.messagingService.notify('error', '', error.error.status.message);
    } else {
      this.messagingService.notify('error', '', 'Invalid Login ID or password. Try Again!');
    }

    return observableThrowError(error);
  }
  private handleResponse(res: Response, that: any): any {
    that.loaderService.hide();
    if (that.blobOutput) {
      return res;
    }
    if (res) {
      const body: any = res;
      let message;
      if ('message' in body.status) {
        message = body.status.message;
      }
      if (body.status.code > -1 && body.status.code !== 200) {
        this.messagingService.notify('error', `Error: ${body.status.code}`, message);
      } else if (message !== 'Success') {
        this.messagingService.notify('success', 'Success', message);
      }
      return body || {};
    }
  }
  private handleError = (error: any, that: any) => {
    if (!this.disableLoader) {
      this.loaderService.hide();
    }
    if (this.dataService.showErrorInUI) {
      let errorMessage = '';
      let errorMessageInArray: string[] = [];
      if (error && error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = error.error.status.message;
        this.messagingService.notify('error', `Error: 999`, errorMessage);
      } else {
        // server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        error.error.data && error.error.data[0] && error.error.data[0].field ? error.error.data.forEach((element: {
          field: string
          message: string
        }) => {
          errorMessageInArray.push(
            element.message)
        }) : '';
        this.messagingService.notify('error', `Error: ${error.status}`,
          `${error.error.status ? errorMessageInArray.length > 0 ? errorMessageInArray.toString().replace(',', '. ') : error.error.status.message : error.message}`);
      }
      return observableThrowError(errorMessage);
    } else {
      this.dataService.showErrorInUI = true;
      return observableThrowError(null);
    }
  }
}
